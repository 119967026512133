import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';

function NormalizeLink(link) {
  if (link) {
    const test = link.search('https://media.giphy.com/media/');
    if (test !== -1) {
      const normalizedLink = link
        .replace('https://media.giphy.com/media/', '')
        .replace(/((\/source.)|(\/giphy.))(gif|mp4)/, '');
      return normalizedLink;
    }
    const test2 = link.search('https://giphy.com/gifs/');
    if (test2 !== -1) {
      const normalizedLink = link.replace('https://giphy.com/gifs/', '').replace('/html5', '');
      return normalizedLink;
    }
    return false;
  }
  return false;
}

const GiphyWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0rem 3rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0;
  }
  position: relative;
`;

const GiphyVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const GiphyLink = styled.a`
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    height: 100%;
  }
  width: calc(100% - 10rem);
  height: calc(100% - 4rem);
  position: absolute;
  top: 2rem;
  right: 5rem;
`;

const GiphyPreview = ({ value }) => {
  const id = NormalizeLink(value.url);
  const src = `https://media.giphy.com/media/${id}/giphy.mp4`;
  const href = `https://giphy.com/gifs/${id}`;
  if (!id) {
    return <div>Неверный Giphy URL</div>;
  }
  return (
    <GiphyWrapper>
      <GiphyVideo loop muted="" webkit-playsinline="true" playsInline autoPlay preload="auto">
        Your browser does not support HTML5 video.
        <source src={src} type="video/mp4" />
      </GiphyVideo>
      <GiphyLink href={href} />
    </GiphyWrapper>
  );
};

GiphyPreview.propTypes = {
  value: PropTypes.any.isRequired,
};

export default GiphyPreview;
