import styled from '@emotion/styled';
import Img from 'gatsby-image';
import React from 'react';
import PropTypes from 'prop-types';
import Link from '@/components/Base/Links';
import Container from '@/components/Base/Container/index';

export const Post = styled.div`
  margin-bottom: ${({ featured }) => (featured ? '2.5rem' : '0')};
  margin-right: ${({ featured }) => (featured ? 'auto' : '0')};
  margin-left: ${({ featured }) => (featured ? 'auto' : '0')};
  position: relative;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  text-overflow: ellipsis;
  display: flex;
  flex: 1;
  overflow: hidden;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.2em;
    padding: 0 ${({ featured }) => (featured ? '0' : '1rem')};
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1.3em;
    padding: 0 ${({ featured }) => (featured ? '0' : '1rem')};
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 1.4em;
    padding: 0 ${({ featured }) => (featured ? '0' : '1rem')};
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    padding: 0;
    padding-left: ${({ odd }) => (odd ? '0' : '1rem')};
    padding-right: ${({ odd, featured }) => (!featured && odd ? '1rem' : '0')};
    font-size: 1.5em;
  }
`;

const StyledLink = styled(props => <Link {...props} />)`
  color: ${({ theme }) => theme.colors.mainText};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ImgStyled = styled(props => <Img {...props} />)`
  &::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.mask};
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }
  @media ${({ theme }) => theme.breakpoints.mobile} {
    height: ${({ featured }) => (featured ? '30rem' : '17rem')};
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: ${({ featured }) => (featured ? '35rem' : '17rem')};
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    height: ${({ featured }) => (featured ? '40rem' : '25rem')};
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    height: ${({ featured }) => (featured ? '45rem' : '25rem')};
  }
`;

const CardContainer = styled(Container)`
  position: ${({ featured }) => (featured ? 'absolute' : 'relative')};
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 !important;
`;

const H1 = styled.h3`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme, featured }) => (featured ? theme.colors.alwaysWhite : theme.colors.grayText)};
  margin: 0;
  margin-bottom: 0.8rem;
  line-height: 1.4em;
  overflow: hidden;
  flex: 1;
  display: flex;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.15rem;
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    font-size: 1.7rem;
  }
  align-items: flex-end;
`;

const Card = ({ title, image, link, featured, count }) => {
  let isOdd = null;
  if (count >= 0) {
    isOdd = (count + 1) % 2 === 1;
  }
  return (
    <Post featured={featured} odd={isOdd}>
      <StyledLink to={`/articles/${link}`} title={title}>
        <CardContainer featured={featured}>
          <H1 featured={featured}>{title}</H1>
        </CardContainer>
        {image && <ImgStyled featured={featured} fluid={image} />}
      </StyledLink>
    </Post>
  );
};

Card.defaultProps = {
  title: '',
  link: '',
  featured: false,
  count: 0,
};

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.any.isRequired,
  link: PropTypes.string,
  featured: PropTypes.bool,
  count: PropTypes.number,
};

export default Card;
