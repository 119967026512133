import styled from '@emotion/styled';
import Img from 'gatsby-image';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const AuthorSection = styled.div`
  position: relative;
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: auto;
  color: ${({ theme, featured }) => (featured ? theme.colors.alwaysWhite : theme.colors.grayText)};
  :hover {
    color: ${({ theme, featured }) => (featured ? theme.colors.alwaysWhite : theme.colors.mainOpacity)};
  }
  margin-bottom: 1rem;
`;

const Avatar = styled.div`
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
`;

const ReadingPanel = styled.div`
  display: flex;
  font-size: 1.3rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
`;

const AvatarImage = styled(props => <Img {...props} />)`
  border-radius: 50%;
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
`;

export default function Author({ name, timetoread, date, avatar, featured, slug }) {
  const to = `/authors/${slug}`;
  return (
    <StyledLink to={to}>
      <AuthorSection featured={featured}>
        <Avatar>
          <AvatarImage fixed={avatar} />
        </Avatar>
        <ReadingPanel>
          <div>{name}</div>
          <div>
            {date}
            {` ${timetoread !== '0' ? `· ${timetoread}` : ''}`}
          </div>
        </ReadingPanel>
      </AuthorSection>
    </StyledLink>
  );
}

Author.defaultProps = {
  featured: false,
  slug: '',
};

Author.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.any.isRequired,
  timetoread: PropTypes.any.isRequired,
  date: PropTypes.any.isRequired,
  avatar: PropTypes.any.isRequired,
  featured: PropTypes.bool,
};
