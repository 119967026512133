import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';

const Title = styled.h1`
  padding-top: 1rem;
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 2.4rem;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    font-size: 3.5rem;
  }
  font-weight: 700;
  margin-bottom: 0.3rem;
  color: ${({ theme }) => theme.colors.blackColor};
  line-height: 1.4em;
`;

const SubTitle = styled.h2`
  margin-bottom: 0.3rem;
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 2rem;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    font-size: 3rem;
  }
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mainOpacity};
  padding-bottom: 1rem;
`;

export default function PostTitle({ title, subtitle }) {
  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </>
  );
}

PostTitle.defaultProps = {
  title: '',
  subtitle: '',
};

PostTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
