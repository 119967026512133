import styled from '@emotion/styled';
import getYoutubeId from 'get-youtube-id';
import React from 'react';
import PropTypes from 'prop-types';

const YoutubeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 3rem 0rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin: 2rem 0;
  }
`;

const YoutubeFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const NoId = styled.div`
  padding: 2rem 0;
`;

const YouTubePreview = ({ value }) => {
  const id = getYoutubeId(value.url);
  const url = `https://www.youtube-nocookie.com/embed/${id}`;
  if (!id) {
    return <NoId>Missing YouTube URL</NoId>;
  }
  return (
    <YoutubeWrapper>
      <YoutubeFrame
        title="YouTube preview"
        width="560"
        height="349"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </YoutubeWrapper>
  );
};

YouTubePreview.propTypes = {
  value: PropTypes.any.isRequired,
};

export default YouTubePreview;
