import { getFluidGatsbyImage } from 'gatsby-source-sanity';

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECTID,
  dataset: process.env.GATSBY_SANITY_DATASET,
};

const fluidProps = source => getFluidGatsbyImage(source, { maxWidth: '1050' }, sanityConfig);

export default fluidProps;
