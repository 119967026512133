import styled from '@emotion/styled';
import PortableText from '@sanity/block-content-to-react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../components/Base/Container';
import Layout from '../components/Base/Layout';
import SEO from '../components/Base/seo';
import serializers from '../components/BlockToText/BlockRenderer';
import Title from '../components/BlockToText/Title';
import Author from '../components/Card/Author';
import Card from '@/components/Card/Card';

const HeaderContainer = styled(props => <Container {...props} />)`
  max-width: 850px;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0 !important;
`;

const BottomCards = styled(props => <Card {...props} />)`
  flex: 1 !important;
`;

const FooterContainer = styled(props => <Container {...props} />)`
  max-width: 850px;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0rem;
`;

const FurtherReading = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const PortableContainer = styled(props => <Container {...props} />)`
  max-width: 850px;
  padding-top: 0rem !important;
  div {
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-top: 2rem;
      min-height: 2.4rem;
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
      > p {
        font-size: 1.4rem;
      }
    }
    @media ${({ theme }) => theme.breakpoints.tablet} {
      > p {
        font-size: 1.5rem;
      }
    }
    @media ${({ theme }) => theme.breakpoints.desktop} {
      > p {
        font-size: 1.6rem;
      }
    }
  }
  ul {
    padding: 0 2rem;
    li {
      font-size: 1.8rem;
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
      > li {
        font-size: 1.4rem;
      }
    }
    @media ${({ theme }) => theme.breakpoints.tablet} {
      > li {
        font-size: 1.4rem;
      }
    }
    @media ${({ theme }) => theme.breakpoints.desktop} {
      > li {
        font-size: 1.5rem;
      }
    }
  }
`;

const SuperContainer = styled.div`
  max-width: 950px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 3rem;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.secondaryText};
`;

const Image = styled(props => <Img {...props} />)`
  width: 100%;
  margin-bottom: 2rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.mainText};
  border-radius: 0.5rem;
  font-size: 1.2rem;
  padding: 0.5rem;
  width: auto;
  display: inline-block;
  text-decoration: none;
  margin-top: 0.5rem;
  :hover,
  :active,
  :focus {
    color: ${({ theme }) => theme.colors.mainText};
    opacity: 0.8;
  }
  margin-right: 0.5rem;
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 1rem;
    padding: 0.3rem;
  }
`;
const BottomTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme, featured }) => (featured ? theme.colors.secondaryText : theme.colors.grayText)};
  margin: 0;
  margin-bottom: 0.8rem;
  line-height: 1.4em;
  overflow: hidden;
  flex: 1;
  display: flex;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.25rem;
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    font-size: 1.8rem;
  }
`;

const IndexPage = ({ pageContext }) => {
  const {
    title,
    _rawBody: body,
    subtitle,
    prev,
    next,
    categories,
    image,
    createdAt: date,
    author,
    timetoread,
    heading,
    slug,
    date: publishedDate,
  } = pageContext;
  const SEOHeading = heading || 'Статья';
  const SEOTitle = `${title} | ${SEOHeading}`;
  const { src: SEOImage } = image || {};
  return (
    <Layout title={title} next={next} prev={prev}>
      <SEO
        title={SEOTitle}
        keywords={categories}
        image={SEOImage}
        description={subtitle}
        date={publishedDate}
        author={author}
        subtitle={timetoread}
        slug={`articles/${slug}`}
      />
      <SuperContainer>
        {image && <Image fluid={image} />}
        <HeaderContainer>
          <Title title={title} subtitle={subtitle} />
          <Author
            name={author.name}
            timetoread={timetoread}
            slug={author.slug.current}
            date={date}
            avatar={author.image.asset.fixed}
          />
          <TagContainer>
            {!!categories.length && categories.map(category => <Tag key={category}>{category}</Tag>)}
          </TagContainer>
        </HeaderContainer>
        <PortableContainer id="id">
          <PortableText blocks={body} serializers={serializers} />
        </PortableContainer>
        <FooterContainer>
          <BottomTitle>Читать дальше:</BottomTitle>
          <FurtherReading>
            {prev?.title && (
              <BottomCards title={prev?.title} image={prev?.image} link={prev?.slug} count={0} featured={false} />
            )}
            {next?.title && (
              <BottomCards title={next?.title} image={next?.image} link={next?.slug} count={1} featured={false} />
            )}
          </FurtherReading>
        </FooterContainer>
      </SuperContainer>
    </Layout>
  );
};

IndexPage.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default IndexPage;
