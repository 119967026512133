import styled from '@emotion/styled';
import Img from 'gatsby-image';
// import _kebabCase from "lodash/kebabCase"
import PropTypes from 'prop-types';
import React from 'react';
// import React, { useState, useCallback } from 'react';
// import blocksToText from "../../../helpers/blockToText"
// import Zoom from 'react-medium-image-zoom';
import fluidProps from '@/helpers/imageURL';
import Giphy from '../Giphy';
import Youtube from '../Youtube';
// eslint-disable-next-line import/no-unresolved
// import 'react-medium-image-zoom/dist/styles.css';

const H1 = styled.h3`
  font-size: 2.8rem;
  font-weight: 600;
  margin-top: 2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2.2rem;
    margin-top: 1rem;
  }
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 2.4rem;
    margin-top: 1.5rem;
  }
`;

const H2 = styled.h4`
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.9rem;
    margin-top: 1rem;
  }
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 2.1rem;
    margin-top: 1.5rem;
  }
`;

const H3 = styled.h5`
  font-size: 2.3rem;
  font-weight: 600;
  margin-top: 2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.7rem;
    margin-top: 1rem;
  }
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 1.9rem;
    margin-top: 1.5rem;
  }
`;

const H4 = styled.h6`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 1.6rem;
    margin-top: 1.5rem;
  }
`;

const BlockQuote = styled.blockquote`
  padding: 1.5rem 0;
  color: ${({ theme }) => theme.colors.blackText};
  font-size: 2rem;
  font-weight: 400;
  font-style: italic;
  line-height: 3rem;
  padding-left: 1rem;
  margin: 2rem 0;
  border-left: 3px solid ${({ theme }) => theme.colors.blackText};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.3rem;
  }
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 1.6rem;
  }
`;

const RichTextImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  @media ${({ theme }) => theme.breakpoints.touch} {
    margin: 2rem 0;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    margin: 2.5rem 0;
  }
`;

const RichTextImageContainerRight = styled.div`
  position: relative;
  float: left;
  @media ${({ theme }) => theme.breakpoints.prebigscreen} {
    width: 100%;
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    width: 50%;
  }
  height: auto;
  @media ${({ theme }) => theme.breakpoints.touch} {
    margin: 2rem 0;
    margin-right: 2.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    margin: 2.5rem 0;
    margin-right: 2.5rem;
  }
`;

const RichTextImageContainerLeft = styled.div`
  position: relative;
  float: right;
  @media ${({ theme }) => theme.breakpoints.prebigscreen} {
    width: 100%;
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    width: 50%;
  }
  height: auto;
  @media ${({ theme }) => theme.breakpoints.touch} {
    margin: 2rem 0;
    margin-left: 2.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    margin: 2.5rem 0;
    margin-left: 2.5rem;
  }
`;

const RichTextImageText = styled.div`
  width: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.mainText};
  text-align: center;
  line-height: 1.5rem;
  padding-top: 0.7rem;
  @media ${({ theme }) => theme.breakpoints.pc} {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
`;

const RichTextImageContainerNoborder = styled.div`
  width: 100vw;
  position: relative;
  @media ${({ theme }) => theme.breakpoints.beyondmaxwidth} {
    transform: translateX(calc((830px - 100vw) / 2));
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.beforemaxwidth} {
    margin-right: 0;
    margin-left: -50vw;
    margin-top: 2rem;
    margin-bottom: 2rem;
    left: 50%;
  }
`;

const RichTextImage = styled(props => <Img {...props} />)`
  width: 100%;
`;

// const TextImage = styled.img`
//   position: absolute;
//   width: ${({ open }) => (open ? '100vw' : '100%')};
//   height: auto;
//   top: 0;
//   right: 0;
//   z-index: ${({ open }) => (open ? '22' : '19')};
// `;

// const TextImage = styled.img`
//   position: absolute;
//   width: ${({ open }) => (open ? '100vw' : '100%')};
//   height: auto;
//   transition: all 0.5s cubic-bezier(0.75, -0.5, 0, 1.75);
//   @media ${({ theme }) => theme.breakpoints.beyondmaxwidth} {
//     transform: ${({ open }) => (open ? 'translateX(calc((830px - 100vw) / 2))' : '0')};
//   }
//   @media ${({ theme }) => theme.breakpoints.beforemaxwidth} {
//     margin-right: 0;
//     margin-left: ${({ open }) => (open ? ' -50vw' : '0')};
//     left: ${({ open }) => (open ? ' -50%' : '0')};
//   }
//   top: 0;
//   left: 0;
//   z-index: ${({ open }) => (open ? '22' : '19')};
// `;

// const Overlay = styled.div`
//   position: fixed;
//   display: ${({ open }) => (open ? 'block' : 'none')};
//   height: 100vh;
//   background-color: ${({ theme }) => theme.colors.mainText};
//   z-index: 22;
//   right: 0;
//   width: 100vw;
//   top: 0;
//   z-index: ${({ open }) => (open ? '21' : '18')};
//   opacity: 0.7;
// `;

const BlockRenderer = ({ node, children }) => {
  const { style = 'normal' } = node;
  switch (style) {
    case 'h1':
      return <H1>{children}</H1>;
    case 'h2':
      return <H2>{children}</H2>;
    case 'h3':
      return <H3>{children}</H3>;
    case 'h4':
      return <H4>{children}</H4>;
    case 'blockquote':
      return <BlockQuote>{children}</BlockQuote>;
    default:
      return <p>{children}</p>;
  }
};

BlockRenderer.propTypes = {
  node: PropTypes.shape({
    style: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
}.isRequired;

const serializers = {
  types: {
    block: BlockRenderer,
    attachmentImage: ({ node }) => {
      const ogFluids = fluidProps(node.asset);
      const fluids = {
        base64: ogFluids.base64,
        aspectRatio: ogFluids.aspectRatio,
        src: (ogFluids.src || '').replace(/fit=crop/gi, 'fit=clip'),
        srcWebp: (ogFluids.srcWebp || '').replace(/fit=crop/gi, 'fit=clip'),
        srcSet: (ogFluids.srcSet || '').replace(/fit=crop/gi, 'fit=clip'),
        srcSetWebp: (ogFluids.srcSetWebp || '').replace(/fit=crop/gi, 'fit=clip'),
        sizes: ogFluids.sizes,
      };
      const direction = (node.direction || 'fullscreen').toLowerCase();
      const alt = node.alt || '';
      const { srcSetWebp: _, ...noSrcWebp } = fluids;
      const { _ref: path = '' } = node.asset;
      const fluid = path.includes('-gif') ? noSrcWebp : fluids;
      // const cloneSrc = fluid.srcWebp || fluid.src;
      // const [open, setOpen] = useState(false);
      // const toggle = useCallback(() => {
      //   setOpen(!open);
      //   console.log({ fluids });
      // }, [open]);
      // const closeClick = useCallback(() => {
      //   setOpen(false);
      // }, []);
      switch (direction) {
        case 'fullscreen':
          return (
            <RichTextImageContainer>
              {/* <Zoom
                image={{
                  src: cloneSrc,
                  alt,
                }}
                zoomImage={{
                  src: cloneSrc,
                  alt,
                }}
              > */}
              <RichTextImage fluid={fluid} alt={alt} />
              {/* </Zoom> */}
              {alt && <RichTextImageText>{alt}</RichTextImageText>}
              {/* <TextImage src={cloneSrc} alt={alt} onClick={toggle} open={open} /> */}
              {/* <Overlay open={open} onClick={closeClick} /> */}
            </RichTextImageContainer>
          );
        case 'right':
          return (
            <RichTextImageContainerRight>
              <RichTextImage fluid={fluid} alt={alt} />
              {alt && <RichTextImageText>{alt}</RichTextImageText>}
            </RichTextImageContainerRight>
          );
        case 'left':
          return (
            <RichTextImageContainerLeft>
              <RichTextImage fluid={fluid} alt={alt} />
              {alt && <RichTextImageText>{alt}</RichTextImageText>}
            </RichTextImageContainerLeft>
          );
        case 'no-border':
          return (
            <RichTextImageContainerNoborder>
              <RichTextImage fluid={fluid} alt={alt} />
              {alt && <RichTextImageText>{alt}</RichTextImageText>}
            </RichTextImageContainerNoborder>
          );
        default:
          return (
            <RichTextImageContainer>
              <RichTextImage fluid={fluid} alt={alt} />
              {alt && <RichTextImageText>{alt}</RichTextImageText>}
            </RichTextImageContainer>
          );
      }
    },
    youtube: ({ node }) => <Youtube value={node} />,
    giphy: ({ node }) => <Giphy value={node} />,
  },
};

serializers.types.attachmentImage.propTypes = {
  node: PropTypes.any.isRequired,
};
serializers.types.youtube.propTypes = {
  node: PropTypes.any.isRequired,
};
serializers.types.giphy.propTypes = {
  node: PropTypes.any.isRequired,
};

export default serializers;
